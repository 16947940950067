//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchUnionpayPaylogList } from "@/api/sale";
import "@/utils/global.js";
import Pagination from "@/components/Pagination";
import { transPayState } from "@/utils/util";
export default {
  name: "unionpayPaylogList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        start_time: "",
        end_time: "",
        status: "",
        keyword: ""
      },
      hightlight: false,
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      total_price: 0,
      total: 0,
      payLogState: [{
        value: 0,
        label: '待支付'
      }, {
        value: 1,
        label: '支付成功'
      }]
    };
  },
  created: function created() {
    this.init();
    this.getTableData();
  },
  methods: {
    init: function init() {
      console.log(this.$route.params.searchForm);

      if (this.$route.params && this.$route.params.searchForm) {
        this.searchForm = JSON.parse(this.$route.params.searchForm);
      }

      if (this.$route.query && this.$route.query.searchForm) {
        this.searchForm = JSON.parse(this.$route.query.searchForm);
      }
    },
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      console.log(data);
      fetchUnionpayPaylogList(data).then(function (response) {
        console.log(response);
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
        _this.total_price = response.meta.total_price;
        _this.total = response.meta.total;
      }).catch(function (err) {
        _this.$message({
          type: "warning",
          message: err.data["message"]
        });

        _this.tableLoading = false;
      });
    },
    handleSearch: function handleSearch() {
      this.searchForm.page = 1;
      this.getTableData();
    },
    // 清空
    handleClear: function handleClear() {
      this.selectTotal = [];
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log("页数");
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleTime: function handleTime(val) {
      console.log("sss", val);

      if (val) {
        this.searchForm["start_time"] = this.$moment(val[0]).format('YYYY-MM-DD HH:mm:ss');
        this.searchForm["end_time"] = this.$moment(val[1]).format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.searchForm["start_time"] = "";
        this.searchForm["end_time"] = "";
      }
    },
    resetForm: function resetForm(formName) {
      this.searchForm["start_time"] = "";
      this.searchForm["end_time"] = "";
      this.$refs[formName].resetFields();
    },
    transPayState: transPayState
  }
};